<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-schedule-mail-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">ویرایش تکرار پست: {{ post?.name }}</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
    </template>
    <template #default>
      <div class="p-5 bg-blueGray-100 border border-blueGray-200 rounded-md mb-5">
        <p>
          برای تعیین پیام دکمه ارسال در ربات را بزنید و سپس پیام مورد نظر را برای ربات
          ارسال کنید.
        </p>

        <div v-if="'ok' in res" class="mt-5">
          <p v-if="res.ok">
            اکنون پیام مورد نظر خود را به ربات
            <a
              :href="'https://t.me/' + res.result.from.username"
              class="text-sky-500 font-bold"
              target="_blank"
            >
              {{ res.result.from.first_name }}
            </a>
            ارسال کنید
          </p>
          <p v-else>
            مشکلی پیش آمد ربات نتوانست به شما پیام دهد. احتمالا ربات را بلاک کرده‌اید یا
            هنوز آن را ارسال نکرده‌اید. به pv ربات گروه بروید و آن را استارت کنید
          </p>
        </div>

        <Button class="mt-5" color="amber" @click="sendPmToBot" :disabled="sending">
          ارسال پیام در ربات
        </Button>
      </div>
      <ErrorBox :errors="errors" class="mb-5" />
      <form action="" @submit.prevent="submit()">
        <div class="grid sm:grid-cols-3 gap-3">
          <MnrText
            v-model="inputs.name"
            title="نام این زمان"
            placeholder="یک نام وارد کنید"
          />
          <MnrSelect
            v-model="inputs.forward.value"
            :options="inputs.forward.options"
            title="نوع ارسال"
            placeholder="انتخاب کنید"
          />
          <MnrSelect
            v-model="inputs.status.value"
            :options="inputs.status.options"
            title="وضعیت"
            placeholder="انتخاب کنید"
          />
        </div>
        <div class="mt-5">
          <MnrCheckSlider v-model:checked="inputs.deleteLast">
            حذف پیام قبلی
          </MnrCheckSlider>
          <p>
            با فعال بودن این گزینه ربات زمانی که پست مورد نظر را ارسال می‌کند پست قبلی
            مشابه را حذف می‌کند و این باعث می‌شود گروه شلوغ نشود.
          </p>
        </div>
        <div class="grid sm:grid-cols-3 gap-3 mt-10">
          <MnrSelect
            v-model="inputs.send.value"
            :options="inputs.send.options"
            title="زمانبندی"
            placeholder="انتخاب کنید"
          />
          <MnrText
            v-model="inputs.start"
            v-show="inputs.send.value == 'period'"
            type="time"
            title="شروع بعدی"
            hint="اگرو شروع بعدی قبل از زمان حاظر باشد روز بعد شروع می‌شود"
          />
        </div>
        <div class="mt-3" v-show="inputs.send.value == 'period'">
          <h2 class="text-lg font-bold">دوره تناوب فاصله بین پست ها</h2>
          <div class="grid grid-cols-3 gap-3 mt-2">
            <MnrNumber
              v-model="inputs.period.min"
              hint="دقیقه"
              before="دقیقه"
              placeholder="0"
              :min="0"
              :max="59"
            ></MnrNumber>
            <MnrNumber
              v-model="inputs.period.hour"
              hint="ساعت"
              before="ساعت"
              placeholder="0"
              :min="0"
              :max="59"
            ></MnrNumber>
            <MnrNumber
              v-model="inputs.period.day"
              hint="روز"
              before="روز"
              placeholder="0"
              :min="0"
            ></MnrNumber>
          </div>
        </div>
        <div v-show="inputs.send.value == 'time'">
          <div class="grid sm:grid-cols-3 gap-3 mt-10">
            <div
              v-for="(time, index) in inputs.times"
              :key="index"
              class="bg-blueGray-100 border border-blueGray-200 rounded-md p-2 gap-2 flex"
            >
              <MnrText v-model="time.value" class="w-full" type="time" />
              <Button
                class="inline-flex text-sm lg:text-lg"
                color="red"
                @click="inputs.times.splice(index, 1)"
              >
                ×
              </Button>
            </div>
          </div>
          <Button
            class="mt-3 inline-flex text-sm h-11 lg:text-lg"
            color="teal"
            @click="inputs.times.push({ value: '' })"
          >
            اضافه کردن
          </Button>
        </div>
        <div class="sm:w-60 w-full mt-3 mx-auto">
          <Button type="submit" class="mt-3 text-sm h-11 lg:text-lg" color="sky">
            ثبت
          </Button>
        </div>
      </form>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import MnrSelect from "@/components/mnr/MnrSelect.vue";
import Button from "@/components/Button.vue";
import ErrorBox from "@/components/ErrorBox.vue";
import MnrNumber from "@/components/mnr/MnrNumber.vue";
import MnrCheckbox from "@/components/mnr/MnrCheckbox.vue";
import MnrCheckSlider from "@/components/mnr/MnrCheckSlider.vue";

export default {
  components: {
    BodySimple,
    Icon,
    MnrText,
    MnrSelect,
    Button,
    ErrorBox,
    MnrNumber,
    MnrCheckSlider,
  },
  data() {
    return {
      firstLoading: true,
      group: {},
      post: {},
      res: {},
      sending: false,
      errors: [],
      inputs: {
        name: "",
        deleteLast: false,
        forward: {
          value: 0,
          options: [
            {
              text: "فوروارد",
              value: 1,
            },
            {
              text: "کپی",
              value: 0,
            },
          ],
        },
        status: {
          value: 1,
          options: [
            {
              text: "فعال",
              value: 1,
            },
            {
              text: "غیر فعال",
              value: 0,
            },
          ],
        },
        send: {
          value: "period",
          options: [
            {
              text: "دوره‌ای",
              value: "period",
            },
            {
              text: "زمانی",
              value: "time",
            },
          ],
        },
        start: null,
        period: {
          min: 0,
          hour: 8,
          day: 0,
        },
        times: [{ value: "" }, { value: "" }, { value: "" }],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get(
          "/api/group-posts/" +
            $this.$route.params.groupId +
            "/" +
            $this.$route.params.repeatId,
          {
            params: {
              id: $this.$store.state.user.id,
              // date: $this.date,
            },
          }
        )
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          $this.post = response.data.post;
          // $this.users = response.data.users;
          // $this.date = response.data.date;

          $this.inputs.name = $this.post.name;
          $this.inputs.deleteLast = $this.post.delete_last;
          $this.inputs.forward.value = $this.post.forward;
          $this.inputs.status.value = $this.post.active;
          $this.inputs.send.value = $this.post.send_type;
          $this.inputs.start = $this.post.next_at.time;
          $this.inputs.period.day = Math.floor($this.post.send_period / (24 * 60));
          $this.inputs.period.hour = Math.floor(
            ($this.post.send_period % (24 * 60)) / 60
          );
          $this.inputs.period.min = ($this.post.send_period % (24 * 60)) % 60;
          $this.inputs.times = response.data.times;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
    submit() {
      let $this = this;
      $this.errors = [];
      // $this.firstLoading = true;
      $this.$axios
        .put(
          "/api/group-posts/" +
            $this.$route.params.groupId +
            "/" +
            $this.$route.params.repeatId,
          {
            id: $this.$store.state.user.id,
            inputs: $this.inputs,
            // search: $this.searchTools,
            // date: $this.date,
          }
        )
        .then(function (response) {
          // console.log(response);
          $this.$router.push({
            name: "GroupOwnerSettingRepeat",
            params: {
              id: $this.$store.state.user.id,
              groupId: $this.$route.params.groupId,
            },
          });
        })
        .catch(function (error) {
          // console.log(error);
          $this.errors = error.data;
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
    sendPmToBot() {
      let $this = this;
      $this.sending = true;
      $this.res = {};
      $this.$axios
        .put(
          "/api/group-posts/" +
            $this.$route.params.groupId +
            "/" +
            $this.$route.params.repeatId +
            "/set-post",
          {
            id: $this.$store.state.user.id,
            // inputs: $this.inputs,
            // search: $this.searchTools,
            // date: $this.date,
          }
        )
        .then(function (response) {
          // console.log(response);
          $this.res = response.data;
          // $this.$router.push({
          //   name: "GroupOwnerSettingRepeat",
          //   params: {
          //     id: $this.$store.state.user.id,
          //     groupId: $this.$route.params.groupId,
          //   },
          // });
        })
        .catch(function (error) {
          // console.log(error);
          // $this.errors = error.data;
        })
        .then(function () {
          // $this.firstLoading = false;
          $this.sending = false;
        });
    },
  },
};
</script>

<style></style>
